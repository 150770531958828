import { Box, Fade, styled } from "@mui/material";
import Loading from "~/components/Loading";
import { BoxProps } from "@mui/material/Box/Box";

const FrameOverlay = styled(Box)(({ theme: { spacing } }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  height: "100%",
  width: "100%",
  pointerEvents: "none",
}));

const LoadingOverlay = ({
  when,
  loadingBg = "grey.200",
  hideSpinner,
}: {
  when?: boolean;
  loadingBg?: BoxProps["bgcolor"];
  hideSpinner?: boolean;
}) => {
  return (
    <Fade in={when} appear={false}>
      <FrameOverlay bgcolor={loadingBg}>{!hideSpinner && <Loading />}</FrameOverlay>
    </Fade>
  );
};

export default LoadingOverlay;
