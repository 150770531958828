import { ConceptLookupResponse, EntityGroupNode } from "catalog/types/interfaces/conceptResponse";
import ConceptCategory, { DISPLAY_CONCEPT_CATEGORY } from "catalog/types/enums/conceptCategory";
import ConceptEntity from "catalog/types/enums/conceptEntity";

export const getTreeNodeById = (
  id?: string,
  node: Record<string, EntityGroupNode | null> = {},
  parentIds: string[] = []
): { node: EntityGroupNode | null; parentIds: string[] } => {
  for (const key in node) {
    if (key === id) {
      // Node found, return it along with the parent ID path
      return { node: node[key] ?? null, parentIds };
    } else if (node[key]?.children) {
      // Recursive call with traversal ID path
      const result = getTreeNodeById(id, node[key]!.children, [...parentIds, key]);
      if (result.node) {
        return result;
      }
    }
  }

  return { node: null, parentIds: [] };
};

/**
 * TODO: Clean this up a bit by combining category display info with lookups backend response
 * Handles converting an ID to human-readable label with correct href
 * @param id - From non-details path, either category string enum or ID that exists in tree response (with subsequent lookup response entry)
 * @param lookup - Lookup table response
 * @param conceptsPath - Prefix path for url (contextual to whether user is in a project or not)
 */
export const mapIdToLink = (id: string, lookup: ConceptLookupResponse, conceptsPath = "") => {
  let href = `${conceptsPath}/${id}`;
  const groupInfo = lookup.data?.[id];
  const categoryConfig = DISPLAY_CONCEPT_CATEGORY[id as ConceptCategory];

  if (categoryConfig) {
    return { label: categoryConfig.title, href, description: categoryConfig.description };
  }

  if (groupInfo) {
    // Extra handling for the fact that question group details have question details inside of them
    if (groupInfo.type === ConceptEntity.QUESTION_GROUP) {
      href += "/details";
    }
    return { label: groupInfo.name, href, description: groupInfo.description };
  }

  return null;
};
