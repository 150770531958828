import { createContext, ReactNode, useContext } from "react";
import User from "~/types/interfaces/user";

const UserContext = createContext<User | null>(null);

export const UserProvider = ({ user, children }: { user: User | null; children: ReactNode }) => {
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

const useUser = () => {
  return useContext(UserContext);
};

export default useUser;
