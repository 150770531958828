import { useFetcher } from "~/hooks/useFetcher";
import ProjectPrivilege from "rwb/pages/project/types/enums/privilege";
import Project from "rwb/types/interfaces/project";
import useUser from "~/contexts/user";
import ProjectRole from "rwb/pages/project/types/enums/projectRole";

const { VITE_RWB_API } = import.meta.env;

export const useRolePrivileges = () => {
  return useFetcher<Record<ProjectRole, ProjectPrivilege[]>>(
    `${VITE_RWB_API}/resources/role-privileges`
  );
};

const useProjectAccess = (privilege: ProjectPrivilege, project?: Project): boolean | null => {
  const user = useUser();
  const projectRole = project?.members.find(
    ({ member_identity }) => member_identity.email === user?.email
  )?.assigned_role;

  const { data } = useRolePrivileges();

  if (projectRole && data) {
    return data[projectRole].includes(privilege);
  }

  return null;
};

export default useProjectAccess;
