import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

interface ModalProps {
  open: boolean;
  stopAll: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

const ModalBody = ({ stopAll, handleClose, handleSubmit }: ModalProps) => {
  const title = stopAll ? "Stop All Confirmation" : "Stop Confirmation";
  const body = stopAll
    ? "Are you sure you want to stop all compute environments for this project?"
    : "Are you sure you want to stop this compute environment? Any running jobs inside it will also stop.";

  return (
    <>
      <DialogTitle>
        {title}
        <IconButton size="small" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ gap: 2, fontSize: 14 }}>{body}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "attention.main",
            "&:hover": { backgroundColor: "attention.dark" },
          }}
          onClick={handleSubmit}
        >
          Stop{`${stopAll ? " All" : ""}`}
        </Button>
      </DialogActions>
    </>
  );
};

const StopEnvironmentModal = (props: ModalProps) => (
  <Dialog fullWidth open={props.open} onClose={props.handleClose}>
    <ModalBody {...props} />
  </Dialog>
);

export default StopEnvironmentModal;
