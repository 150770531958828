import { Box, styled } from "@mui/material";
import { BoxProps } from "@mui/material/Box/Box";

const StatusIndicator = styled(Box, { shouldForwardProp: (prop) => prop !== "active" })<
  BoxProps & { active: boolean }
>(({ theme, active }) => ({
  height: 12,
  width: 12,
  flexShrink: 0,
  borderRadius: "50%",
  backgroundColor: active ? theme.palette.status.running : "transparent",
  border: active ? "none" : `2px solid ${theme.palette.grey[300]}`,
}));

export default StatusIndicator;
