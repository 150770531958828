import { Box, CircularProgress } from "@mui/material";
import { CircularProgressProps } from "@mui/material/CircularProgress/CircularProgress";

const Loading = (props: CircularProgressProps) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100%"
    width="100%"
    flex={1}
  >
    <CircularProgress {...props} />
  </Box>
);

export default Loading;
