enum ProjectPrivilege {
  READ_NOTEPAD = "read:notepad",
  READ_APPS = "read:apps",
  WRITE_NOTEPAD = "write:notepad",
  WRITE_APPS = "write:apps",
  MANAGE_PROJECT = "manage:project",
  MANAGE_COMPUTE = "manage:compute-environments",
  MANAGE_COLLABORATORS = "manage:collaborators",
  DOWNLOAD_PROJECT_FILES = "download:workbench-files",
  DELETE_PROJECT = "delete:workbench",
}

export default ProjectPrivilege;
