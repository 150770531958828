import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import theme from "~/theme";
import routes from "~/routes";
import User from "~/types/interfaces/user";
import { identify } from "~/utils/monitoring";
import { UserProvider } from "~/contexts/user";
import { AppHeaderProvider } from "~/contexts/header";
import RouterError from "~/components/RouterError";
import useRecordPageView from "~/hooks/useRecordPageView";

const App = () => {
  useRecordPageView();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (!user) {
      fetch("/me").then(async (resp) => {
        const user = await resp.json();
        setUser(user);
      });
    } else {
      identify(user);
    }
  }, [user]);

  const router = createBrowserRouter(
    routes.map((route) => ({
      ...route,
      // ID is necessary for useRouteError in RouterError
      id: route.path,
      errorElement: <RouterError />,
    }))
  );

  return (
    <React.StrictMode>
      <UserProvider user={user}>
        <AppHeaderProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <RouterProvider router={router} />
          </ThemeProvider>
        </AppHeaderProvider>
      </UserProvider>
    </React.StrictMode>
  );
};

export default App;
