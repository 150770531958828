import { useLocalStorage } from "react-use";

interface UserPreferences {
  disableStopEnvPrompt?: boolean;
  previousApp?: string;
}

/**
 * Manages temporary user preferences in client side storage
 */
export const useUserPreferences = (): [
  preferences: UserPreferences,
  updatePreferences: (update: UserPreferences) => void
] => {
  const key = "sc-user-preferences";
  const [preferences, setPreferences] = useLocalStorage<UserPreferences>(key);

  const updatePreferences = (update: UserPreferences) =>
    setPreferences({ ...preferences, ...update });

  return [preferences ?? {}, updatePreferences];
};
