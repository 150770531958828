import { useFetcher } from "~/hooks/useFetcher";
import { ConceptLookupResponse } from "catalog/types/interfaces/conceptResponse";
import { useSemanticCatalogEnabled } from "catalog/hooks/useCatalogEnabled";

const { VITE_CONCEPT_API } = import.meta.env;

const useConceptsLookup = () => {
  const enabled = useSemanticCatalogEnabled();
  return useFetcher<ConceptLookupResponse>(enabled ? `${VITE_CONCEPT_API}/browse/lookup` : null);
};

export default useConceptsLookup;
