import { useMemo, useRef, useState } from "react";
import useSWR from "swr";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemTypeMap,
  styled,
  svgIconClasses,
  SvgIconProps,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Circle } from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface ServerEndpoint {
  endpoint: string;
  label: string;
}
type ServerHealthLabel = "Healthy" | "Unhealthy" | "Down";

const SERVER_HEALTH_COLORS: Record<ServerHealthLabel, SvgIconProps["color"]> = {
  Healthy: "success",
  Unhealthy: "warning",
  Down: "error",
};

const healthFetcher = (servers: ServerEndpoint[]) => {
  return Promise.allSettled(
    servers.map(async ({ endpoint }) => {
      const res = await fetch(`${endpoint}/health`);
      // Ok or cached response is good
      if (res.ok || res.status === 304) {
        return "OK";
      }
      const error: any = new Error();
      const resp = await res.json();
      error.message = JSON.stringify(resp);
      error.status = res.status;
      throw error;
    })
  );
};

const useServerHealthChecks = (servers: ServerEndpoint[] | undefined) => {
  const [open, setOpen] = useState(false);
  const openDetails = () => setOpen(true);
  const closeDetails = () => setOpen(false);
  const ref = useRef(null);
  const healthSwr = useSWR<PromiseSettledResult<string>[]>(servers ?? null, healthFetcher);
  const serverHealth = useMemo<Record<string, PromiseSettledResult<string>> | null>(
    () =>
      servers?.reduce(
        (all, { label }, index) => ({
          ...all,
          [label]: healthSwr.data?.at(index),
        }),
        {}
      ) || null,
    [servers, healthSwr]
  );
  const summary = useMemo<{
    servers: Record<string, ServerHealthLabel | never>;
    color: SvgIconProps["color"];
  }>(() => {
    if (!serverHealth || healthSwr.isLoading) {
      return {
        servers: {},
        color: "disabled",
      };
    }
    const numServers = Object.keys(serverHealth).length;
    const servers = Object.entries(serverHealth).reduce((all, [label, healthResult]) => {
      const status = {
        healthy: healthResult.status === "fulfilled" && healthResult.value.startsWith("OK"),
        unhealthy: healthResult.status === "fulfilled" && !healthResult.value.startsWith("OK"),
        down: healthResult.status === "rejected",
      };
      return {
        ...all,
        [label]: status.healthy ? "Healthy" : status.unhealthy ? "Unhealthy" : "Down",
      };
    }, {});
    const numUnhealthyServers = Object.values(servers).filter(
      (status) => status === "Unhealthy" || status === "Down"
    ).length;
    return {
      servers,
      color:
        numUnhealthyServers === numServers
          ? "error"
          : numUnhealthyServers > 0
          ? "warning"
          : "success",
    };
  }, [serverHealth, healthSwr.isLoading]);

  return {
    open,
    openDetails,
    closeDetails,
    ref,
    isLoading: healthSwr.isLoading,
    summary,
  };
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  userSelect: "none",
  color: theme.palette.common.white,
  gap: theme.spacing(1.5),
})) as OverridableComponent<ListItemTypeMap<ListItemProps, "li">>;

const StyledListItemIcon = styled(ListItemIcon)({
  color: "inherit",
  justifyContent: "center",
  minWidth: 30,
});

const ServerHealth = ({ serverEndpoints }: { serverEndpoints: ServerEndpoint[] }) => {
  const theme = useTheme();
  const serverHealth = useServerHealthChecks(serverEndpoints);

  return (
    <Tooltip
      arrow
      sx={{
        cursor: "pointer",
        height: 44,
        alignItems: "center",
        [`&:hover .${svgIconClasses.root}`]: {
          borderColor: `${theme.palette.primary.dark}80`,
          filter: "brightness(150%)",
        },
      }}
      placement="bottom-start"
      title={
        <List>
          {Object.entries(serverHealth.summary.servers).map(([label, health]) => (
            <StyledListItem
              key={label}
              sx={{
                fontSize: 14,
                gap: 0.5,
              }}
            >
              <StyledListItemIcon>
                <Circle
                  sx={{
                    fontSize: 14,
                    filter: "brightness(150%)",
                  }}
                  fontSize="small"
                  color={SERVER_HEALTH_COLORS[health]}
                />
              </StyledListItemIcon>
              {label}: {health}
            </StyledListItem>
          ))}
        </List>
      }
      slotProps={{
        tooltip: {
          sx: {
            p: 0,
          },
        },
      }}
    >
      <StyledListItemIcon>
        <Circle
          fontSize="small"
          color={serverHealth.summary.color}
          sx={{
            fontSize: 16,
            filter: "brightness(140%)",
            transition: "all 0.3s ease",
          }}
        />
      </StyledListItemIcon>
    </Tooltip>
  );
};

export default ServerHealth;
