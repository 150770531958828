import camelize from "camelize-ts";
import { omit, pick } from "ramda";
import snakify from "snakify-ts";

/**
 * Performs camelization of object keys.
 * @param obj
 * @param shallow Default is deep camelize, otherwise camelize only the top-level keys
 * @param skipKeys If doing deep camelize, can specify which keys should be shallow camelized instead.
 *                 If doing shallow, can specify which keys should not be camelized at all.
 */
export const safelyCamelize = (obj: unknown, shallow = false, skipKeys: string[] = []): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => safelyCamelize(item, shallow, skipKeys));
  }
  if (!obj || typeof obj !== "object") {
    return obj;
  }
  const skipped = shallow ? pick(skipKeys, obj) : camelize(pick(skipKeys, obj), true);
  return {
    ...camelize(omit(skipKeys, obj), shallow),
    ...skipped,
  };
};

export const partialSnakify = ({ data, omitKeys }: { data: unknown; omitKeys: string[] }) => ({
  ...pick(omitKeys, data),
  ...snakify(omit(omitKeys, data)),
});
