import queryString from "query-string";
import { useDebounce } from "~/hooks/useDebounce";
import { useFetcher } from "~/hooks/useFetcher";
import useFilterParams from "catalog/hooks/useFilterParams";
import SearchResponse from "catalog/types/interfaces/catalogResponse";
import { buildFilterMatrix } from "catalog/utils/buildFilterMatrix";
import useCatalogEnabled from "catalog/hooks/useCatalogEnabled";

export const PAGE_SIZE = 20;
const { VITE_CATALOG_API } = import.meta.env;
const INDICES = {
  table: "catalog-api-table_search_index",
  column: "catalog-api-column_search_index",
};

const useCatalogSearch = <T>(entityType: "table" | "column") => {
  const catalogEnabled = useCatalogEnabled();
  const [params] = useFilterParams();
  const { q: search, page, tags = [] } = params;
  const debouncedSearch = useDebounce(search);
  const filters = buildFilterMatrix(tags as string[]);

  const from = page ? (page - 1) * PAGE_SIZE : 0;

  const qs = queryString.stringify({
    index: INDICES[entityType],
    q: debouncedSearch,
    query_filter: JSON.stringify(filters),
    from,
    size: PAGE_SIZE,
  });

  return useFetcher<SearchResponse<T>>(
    catalogEnabled ? `${VITE_CATALOG_API}/search/query?${qs}` : null,
    {
      keepPreviousData: true,
    }
  );
};

export default useCatalogSearch;
