import yn from "yn";
import { PropsWithChildren, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Alert,
  Box,
  cardClasses,
  Container,
  Link,
  Skeleton,
  Stack,
  styled,
  Tab,
  tabClasses,
  Tabs,
  tabsClasses,
  Typography as T,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import BasicButton from "~/components/BasicButton";
import CreateProject from "rwb/pages/projects/components/CreateProject";
import {
  CampaignToolIcon,
  ParticipantSearchToolIcon,
  PlusIcon,
  SurveyToolIcon,
  TaskToolIcon,
} from "~/assets/icons";
import InteractiveCard from "~/components/InteractiveCard";
import useProjects from "rwb/hooks/useProjects";
import ProjectList from "rwb/pages/projects/components/ProjectList";
import { useDataBrowserSections, DataBrowserTile } from "~/pages/DataBrowser";
import useUser from "~/contexts/user";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";
import useAppHeader from "~/contexts/header";

const { VITE_RWB_ENABLED, VITE_SM_ENABLED } = import.meta.env;

const StyledTabs = styled(Tabs)(({ theme }) => ({
  border: "none",
  [`.${tabsClasses.flexContainer}`]: {
    gap: theme.spacing(2),
    [`.${tabClasses.selected}`]: {
      color: "inherit",
      fontWeight: 600,
    },
  },
  [`.${tabsClasses.indicator}`]: {
    display: "none",
  },
}));

const Section = (props: { title: string } & PropsWithChildren) => (
  <Box
    component="section"
    sx={{
      pt: 2.5,
      pb: 5,
      ":nth-of-type(2n)": {
        backgroundColor: "common.pageBackground",
        [`& .${cardClasses.root}`]: {
          bgcolor: "common.dimmedPageBackground",
        },
      },
      ":last-of-type": { flex: 1 },
    }}
  >
    <Container>
      <T variant="h6" fontWeight={550}>
        {props.title}
      </T>
      {props.children}
    </Container>
  </Box>
);

const PROJECTS_LIMIT = 4;

const WorkbenchWidget = () => {
  const projects = useProjects(PROJECTS_LIMIT);
  const loading = projects.isLoading;
  const empty = !loading && !projects.data?.total;

  return (
    <Section title="Workbench">
      {!empty && (
        <Box display="flex" gap={1} mt={2} mb={1}>
          {loading ? (
            <>
              <Skeleton variant="rounded" width={100} height={35} />
              <Skeleton variant="rounded" width={120} height={35} />
            </>
          ) : (
            <>
              {Number(projects.data?.total) > PROJECTS_LIMIT && (
                <BasicButton component={RouterLink} to="/projects">
                  All Projects
                </BasicButton>
              )}
              <CreateProject
                callToAction={<BasicButton startIcon={<PlusIcon />}>New Project</BasicButton>}
              />
            </>
          )}
        </Box>
      )}
      <ProjectList data={projects} pageSize={PROJECTS_LIMIT} showPagination={false} />
    </Section>
  );
};

const DataBrowserWidget = () => {
  const { sections, loading, error } = useDataBrowserSections();
  const [selectedTab, setSelectedTab] = useState("0");

  return (
    <Section title="Data Browser">
      {loading && !error ? (
        <Box>
          <Box display="flex" gap={2} mt={1.5} mb={1}>
            <Skeleton width={60} />
            <Skeleton width={90} />
          </Box>
          <Grid container spacing={2} columns={15}>
            {Array.from({ length: 5 }).map((_, i) => (
              <Grid key={i} xs={7.5} sm={5} md={3}>
                <Skeleton variant="rounded" height={170} />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <TabContext value={selectedTab}>
          {error && (
            <Alert severity="error" variant="outlined" sx={{ my: 2 }}>
              Unable to load data
            </Alert>
          )}
          <StyledTabs
            value={selectedTab}
            onChange={(_, value) => setSelectedTab(value)}
            sx={{ minHeight: 16 }}
          >
            {sections.length > 1 &&
              sections.map((section, i) => (
                <Tab key={section.title} label={section.title} value={String(i)} />
              ))}
          </StyledTabs>
          {sections.map((section, i) => (
            <TabPanel key={section.title} value={String(i)} sx={{ p: 0 }}>
              <Grid container spacing={2} columns={15}>
                {section.tiles.map((props) => (
                  <Grid key={props.label} xs={7.5} sm={5} md={3}>
                    <DataBrowserTile key={props.label} {...props} />
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          ))}
        </TabContext>
      )}
    </Section>
  );
};

const StudyManagementWidget = () => {
  const links = [
    {
      url: "/sm/participants",
      icon: <ParticipantSearchToolIcon />,
      title: "Search Participants",
      description:
        "Look up participants to view detailed information about their demographics and study participation.",
    },
    {
      url: "/sm/surveys",
      icon: <SurveyToolIcon />,
      title: "Manage Surveys",
      description: "Build a survey to send to participants.",
    },
    {
      url: "/sm/outreach",
      icon: <CampaignToolIcon />,
      title: "Contact Participants",
      description: "Send surveys or other study-related information to a group of participants.",
    },
    {
      url: "/sm/tasks",
      icon: <TaskToolIcon />,
      title: "Manage Tasks",
      description:
        "Manage tasks related to participants' self-reported cancers, revocations or specific study information.",
    },
  ];

  return (
    <Section title="Study Management">
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {links.map((link) => (
          <Grid key={link.url} sm={6} md={3} sx={{ width: "100%" }}>
            <Link component={RouterLink} to={link.url} underline="none">
              <InteractiveCard
                sx={{ p: 3, minHeight: ["none", 210], justifyContent: "space-between" }}
              >
                <Box width={30} height={30}>
                  {link.icon}
                </Box>
                <T variant="body1" fontWeight={500} mt={2} mb={2}>
                  {link.title}
                </T>
                <T
                  variant="body2"
                  sx={{
                    opacity: 0.7,
                    flex: 1,
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: "4",
                  }}
                >
                  {link.description}
                </T>
              </InteractiveCard>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

const title = "Home";

const Home = () => {
  const user = useUser();
  useDocumentTitle(title);
  useAppHeader({ title });
  const showDb = !!user;
  const showRwb = yn(VITE_RWB_ENABLED) && user?.permissions?.includes("view:workbench-app");
  const showSm = yn(VITE_SM_ENABLED) && user?.permissions?.includes("view:sm-app");

  return (
    <Stack bgcolor="common.dimmedPageBackground" minHeight="100%">
      {showSm && <StudyManagementWidget />}
      {showRwb && <WorkbenchWidget />}
      {showDb && <DataBrowserWidget />}
    </Stack>
  );
};

export default Home;
