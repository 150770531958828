import { Alert, Box } from "@mui/material";
import React, { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import rumClient from "~/utils/monitoring";

const RouterError = () => {
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      console.error(error);
      rumClient?.recordError(error);
    }
  }, [error]);

  return (
    <Box display="flex" justifyContent="center" width="100%" py={2}>
      <Alert severity="error" variant="outlined">
        Unhandled application error
      </Alert>
    </Box>
  );
};

export default RouterError;
