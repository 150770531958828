import { Dispatch, SetStateAction, useCallback, useState, ElementType } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Badge, Box, Tooltip, TooltipProps, Typography as T } from "@mui/material";
import { LoadingButton, loadingButtonClasses, LoadingButtonProps } from "@mui/lab";
import StopIcon from "@mui/icons-material/Stop";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ErrorIcon from "@mui/icons-material/Error";
import { DISPLAY_COMPUTE_IMAGE } from "rwb/pages/project/types/enums/imageType";
import EnvironmentStatus from "rwb/pages/project/types/enums/environmentStatus";
import { useUserPreferences } from "~/hooks/useUserPreferences";
import StopEnvironmentModal from "rwb/pages/project/components/environments/StopEnvironmentModal";
import { ComputeEnvironment } from "rwb/types/interfaces/project";

const { VITE_RWB_API } = import.meta.env;

type ActionsVariant = "simple" | "full";

interface ButtonProps {
  variant: ActionsVariant;
  environment: ComputeEnvironment;
  refreshEnvs: () => Promise<void>;
  setError: Dispatch<SetStateAction<string>>;
}

const ActionButton = ({ sx, ...rest }: LoadingButtonProps<ElementType>) => (
  <LoadingButton
    variant="contained"
    color="secondary"
    size="small"
    sx={{
      bgcolor: "grey.200",
      "&:hover": { bgcolor: "grey.300" },
      "> svg": {
        width: 22,
        height: 22,
      },
      [`& .${loadingButtonClasses.loadingIndicator}`]: { color: "grey.600", left: 20 },
      height: 40,
      ...(sx || {}),
    }}
    {...rest}
  />
);

const DelayedTooltip = (props: TooltipProps) => <Tooltip enterDelay={750} arrow {...props} />;

const ConnectButton = ({ variant, environment }: Omit<ButtonProps, "refreshEnvs" | "setError">) => {
  const showLabel = variant === "full";
  const { id, project_id, container_status, image_type } = environment;
  const { icon: Icon } = DISPLAY_COMPUTE_IMAGE[image_type];
  const loading = container_status == EnvironmentStatus.PENDING;

  return (
    <ActionButton
      loading={loading}
      component={RouterLink}
      to={`/projects/${project_id}/environments/${id}`}
    >
      <Icon opacity={loading ? 0 : 1} />
      <T variant="body2" ml={1} mr={0.5} display={showLabel ? ["none", "none", "block"] : "none"}>
        Launch
      </T>
    </ActionButton>
  );
};

const StopButton = ({ variant, environment, refreshEnvs, setError }: ButtonProps) => {
  const showTooltip = variant === "full";
  const { id, status, container_status } = environment;
  const [modalOpen, setModalOpen] = useState(false);
  const [preferences, updatePreferences] = useUserPreferences();
  const [submitting, setSubmitting] = useState(false);

  const stopEnvironment = useCallback(
    async (disablePrompt?: boolean) => {
      setSubmitting(true);
      setModalOpen(false);
      const environmentsUrl = `${VITE_RWB_API}/environments`;

      if (disablePrompt) {
        updatePreferences({ disableStopEnvPrompt: true });
      }

      const resp = await fetch(`${environmentsUrl}/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ state: EnvironmentStatus.STOPPED }),
      });

      if (resp.ok) {
        await refreshEnvs();
      } else {
        setError("Unable to stop compute environment");
      }

      setSubmitting(false);
    },
    [id, refreshEnvs, setError, updatePreferences]
  );

  const handleClick = useCallback(async () => {
    // Avoid prompting if user has indicated they'd like to skip dialog in past
    if (preferences.disableStopEnvPrompt) {
      await stopEnvironment();
    } else {
      setModalOpen(true);
    }
  }, [preferences, stopEnvironment]);

  const isFailed = container_status === EnvironmentStatus.FAILED;
  const isStopping = submitting || status !== EnvironmentStatus.RUNNING;

  let tooltip;
  if (isFailed) {
    tooltip = "Failed to stop environment, try again";
  } else if (isStopping) {
    tooltip = "Stopping...";
  } else {
    tooltip = "Stop environment";
  }

  return (
    <>
      <DelayedTooltip title={showTooltip && tooltip}>
        <span>
          <ActionButton loading={isStopping} onClick={handleClick} loadingPosition="center">
            <StopIcon />
          </ActionButton>
        </span>
      </DelayedTooltip>
      <StopEnvironmentModal
        stopAll={false}
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleSubmit={stopEnvironment}
      />
    </>
  );
};

const StartButton = ({ variant, environment, refreshEnvs, setError }: ButtonProps) => {
  const showTooltip = variant === "full";
  const { id, status, container_status } = environment;
  const [submitting, setSubmitting] = useState(false);

  const startEnvironment = async () => {
    const environmentsUrl = `${VITE_RWB_API}/environments`;
    setSubmitting(true);

    const resp = await fetch(`${environmentsUrl}/${id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ state: EnvironmentStatus.RUNNING }),
    });

    if (resp.ok) {
      await refreshEnvs();
    } else {
      setError("Unable to start compute environment");
    }

    setSubmitting(false);
  };

  const isFailed = container_status === EnvironmentStatus.FAILED;
  const isLoading = submitting || status !== EnvironmentStatus.STOPPED;

  let tooltip;
  if (isFailed) {
    tooltip = "Failed to start environment, try again";
  } else if (isLoading) {
    tooltip = "Starting...";
  } else {
    tooltip = "Start environment";
  }

  return (
    <DelayedTooltip title={showTooltip && tooltip}>
      <Badge invisible={!isFailed} badgeContent={<ErrorIcon color="secondary" />}>
        <ActionButton
          loading={submitting || status !== EnvironmentStatus.STOPPED}
          onClick={startEnvironment}
        >
          <PlayArrowIcon sx={{ opacity: isLoading ? 0 : 1, color: "status.running" }} />
        </ActionButton>
      </Badge>
    </DelayedTooltip>
  );
};

const EnvironmentActions = ({
  env,
  setError,
  variant = "simple",
  refreshEnvs,
}: {
  env: ComputeEnvironment;
  setError: Dispatch<SetStateAction<string>>;
  variant?: ActionsVariant;
  refreshEnvs: () => Promise<any>;
}) => {
  const showConnect = env.status === EnvironmentStatus.RUNNING;
  const showStop = [EnvironmentStatus.STOPPING, EnvironmentStatus.RUNNING].includes(env.status);
  const showStart = [EnvironmentStatus.PENDING, EnvironmentStatus.STOPPED].includes(env.status);

  return (
    <Box display="flex" gap={0.75}>
      {showConnect && <ConnectButton variant={variant} environment={env} />}
      {showStop && (
        <StopButton
          variant={variant}
          environment={env}
          refreshEnvs={refreshEnvs}
          setError={setError}
        />
      )}
      {showStart && (
        <StartButton
          variant={variant}
          environment={env}
          refreshEnvs={refreshEnvs}
          setError={setError}
        />
      )}
    </Box>
  );
};

export default EnvironmentActions;
