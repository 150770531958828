import AppContainer from "~/components/AppContainer";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";
import useAppHeader from "~/contexts/header";

const title = "Dashboards";

const Dashboards = () => {
  useDocumentTitle(title);
  useAppHeader({ title });
  // Handle PR slot subdomains by using only last 4 segments of hostname
  const hostname = window.location.hostname.split(".").slice(-4).join(".");
  const url = `https://analytics.${hostname}/`;
  return <AppContainer iframeSrc={url} />;
};

export default Dashboards;
