import { ComponentProps, FunctionComponent } from "react";
import { CancerIcon, SurveysIcon } from "~/assets/icons";

enum ConceptCategory {
  CANCER = "cancer",
  SURVEY = "survey",
}

interface CategoryDetails {
  title: string;
  icon: FunctionComponent<ComponentProps<"svg">>;
  color: string;
  tooltip?: string;
  description?: string;
}

export const DISPLAY_CONCEPT_CATEGORY: Record<ConceptCategory, CategoryDetails> = {
  [ConceptCategory.CANCER]: {
    title: "Cancers",
    icon: CancerIcon,
    color: "#DD37F8",
    tooltip:
      "Cancer concepts that describe participant cancer characteristics, such as topography and histology.",
    description:
      "Cancers are categorized into a two-tier hierarchy: the top level names the site using ICDO3 Topography codes (e.g., C50: Breast), while the next level details histological groups using the first three digits of ICDO3 Morphology codes (801-814: Carcinomas).",
  },
  [ConceptCategory.SURVEY]: {
    title: "Surveys",
    icon: SurveysIcon,
    color: "#0066DE",
    tooltip:
      "Survey concepts that describe a range of information about participants collected through surveys, such as diet, lifestyle, and family medical history.",
  },
};

export default ConceptCategory;
