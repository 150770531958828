import { ComponentProps, FunctionComponent, useMemo } from "react";
import pluralize from "pluralize";
import { Link as RouterLink } from "react-router-dom";
import {
  Alert,
  Box,
  CardContent,
  Container,
  Link,
  Skeleton,
  Stack,
  Typography as T,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon/SvgIcon";
import { DashboardOutlined } from "@mui/icons-material";
import PageHeader from "~/components/PageHeader";
import InteractiveCard, { IconContainer } from "~/components/InteractiveCard";
import useCatalogEnabled, { useSemanticCatalogEnabled } from "catalog/hooks/useCatalogEnabled";
import useCatalogSearch from "catalog/hooks/useCatalogSearch";
import { TableEntity, VariableEntity } from "catalog/types/interfaces/catalogResponse";
import useConceptGroupsList, { ConceptGroup } from "catalog/hooks/useConceptGroupsList";
import { DISPLAY_CONCEPT_ENTITY } from "catalog/types/enums/conceptEntity";
import { DatasetIcon, FilesToolIcon, PinIcon, VariableIcon } from "~/assets/icons";
import usePinnedEntities from "catalog/hooks/usePinnedEntities";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";
import useAppHeader from "~/contexts/header";
import useUser from "~/contexts/user";

interface TileProps {
  label: string;
  description: string;
  color?: string;
  href: string;
  icon?: typeof SvgIcon | FunctionComponent<ComponentProps<"svg">>;
}

const numFormatter = Intl.NumberFormat("en", { notation: "compact" });

export const useDataBrowserSections = () => {
  const user = useUser();
  const catalogEnabled = useCatalogEnabled();
  const semanticCatalogEnabled = useSemanticCatalogEnabled();
  const {
    data: tables,
    isLoading: loadingTables,
    error: tableError,
  } = useCatalogSearch<TableEntity>("table");
  const {
    data: columns,
    isLoading: loadingColumns,
    error: columnError,
  } = useCatalogSearch<VariableEntity>("column");
  const { total: totalPinned } = usePinnedEntities();
  const {
    typeCategorizedGroups: conceptGroups,
    loading: loadingConcepts,
    error: conceptsError,
  } = useConceptGroupsList();

  const loading = !user || loadingTables || loadingColumns || loadingConcepts;
  const tablesTotal = Number(tables?.hits?.total?.value);
  const columnsTotal = Number(columns?.hits?.total?.value);

  const sections = useMemo(() => {
    const result: { title: string; tiles: TileProps[] }[] = [];

    const reportTiles = [];

    if (import.meta.env.VITE_DATA_DASHBOARD_URL) {
      reportTiles.push({
        href: "/data-overview",
        label: "Overview",
        description: "Top level summary of organization data",
        icon: DashboardOutlined,
      });
    }

    if (conceptGroups && semanticCatalogEnabled) {
      // Get children from top level group response
      const concepts = conceptGroups.flatMap((group) => group.conceptGroups as ConceptGroup[]);
      if (concepts.length) {
        reportTiles.push(
          ...concepts.map((concept) => {
            const formattedCount = concept.counts
              .map(
                ({ type, count }) =>
                  `${numFormatter.format(count)} ${pluralize(DISPLAY_CONCEPT_ENTITY[type], count)}`
              )
              .join(" · ");

            return {
              href: `/catalog/concepts/${concept.id}`,
              label: concept.name,
              description: formattedCount.toLowerCase(),
              icon: concept.icon,
              color: concept.color,
            };
          })
        );
      }
    }

    if (reportTiles.length) {
      result.push({ title: "Reports", tiles: reportTiles });
    }

    const assetTiles: TileProps[] = [
      {
        href: "/files",
        label: "Files",
        description: "Personal, project and organization files",
        icon: FilesToolIcon,
        color: "#FF8D24",
      },
    ];

    if (catalogEnabled) {
      if (tablesTotal) {
        assetTiles.push({
          href: "/catalog/tables",
          label: "Tables",
          description: `${numFormatter.format(tablesTotal)} tables`,
          icon: DatasetIcon,
        });
      }

      if (columnsTotal) {
        assetTiles.push({
          href: "/catalog/columns",
          label: "Columns",
          description: `${numFormatter.format(columnsTotal)} columns`,
          icon: VariableIcon,
        });
      }

      assetTiles.push({
        href: "/catalog/pins",
        label: "Pins",
        description: `${numFormatter.format(totalPinned)} pinned`,
        icon: PinIcon,
      });
    }

    result.push({
      title: "Data Assets",
      tiles: assetTiles,
    });

    return result;
  }, [catalogEnabled, semanticCatalogEnabled, conceptGroups, tablesTotal, columnsTotal]);

  return { sections, loading, error: Boolean(tableError || columnError || conceptsError) };
};

export const DataBrowserTile = ({ href, icon: Icon, color, label, description }: TileProps) => {
  return (
    <Link component={RouterLink} underline="none" to={href}>
      <InteractiveCard>
        <CardContent sx={{ minHeight: 176, px: 3, pt: 3, gap: 3 }}>
          {Icon && (
            <IconContainer sx={{ bgcolor: color ?? "#959BAC", color: "common.white" }}>
              <Icon />
            </IconContainer>
          )}
          <Stack>
            <T variant="body1" fontWeight={500}>
              {label}
            </T>
            <T
              variant="body2"
              sx={{
                opacity: 0.7,
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "2",
              }}
            >
              {description}
            </T>
          </Stack>
        </CardContent>
      </InteractiveCard>
    </Link>
  );
};

const Loading = () => (
  <Stack gap={1}>
    <Skeleton variant="text" width={100} height={35} />
    <Grid container spacing={2}>
      {Array.from({ length: 6 }).map((_, i) => (
        <Grid key={i} xs={6} sm={4}>
          <Skeleton variant="rounded" height={175} />
        </Grid>
      ))}
    </Grid>
  </Stack>
);

const title = "Data Browser";

const DataBrowser = () => {
  useDocumentTitle(title);
  useAppHeader({ title });
  const { sections, loading, error } = useDataBrowserSections();

  return (
    <Box bgcolor="common.dimmedPageBackground" minHeight="100%">
      <Container maxWidth="md" sx={{ py: 3 }}>
        {loading && !error ? (
          <Loading />
        ) : (
          <Stack gap={4}>
            {error && (
              <Alert severity="error" variant="outlined">
                Unable to load data
              </Alert>
            )}
            {sections.map((section) => (
              <Stack key={section.title} gap={2}>
                {sections.length > 1 && <PageHeader>{section.title}</PageHeader>}
                <Grid container spacing={2}>
                  {section.tiles.map((props) => (
                    <Grid key={props.label} xs={6} sm={3}>
                      <DataBrowserTile {...props} />
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            ))}
          </Stack>
        )}
      </Container>
    </Box>
  );
};

export default DataBrowser;
