import { ReactElement, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { KeyedMutator } from "swr";
import {
  Alert,
  Avatar,
  avatarClasses,
  AvatarGroup,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography as T,
} from "@mui/material";
import { PlusIcon, SettingsToolIcon } from "~/assets/icons";
import { DISPLAY_COMPUTE_IMAGE } from "rwb/pages/project/types/enums/imageType";
import { ComputeEnvironment } from "rwb/types/interfaces/project";
import EnvironmentStatus from "rwb/pages/project/types/enums/environmentStatus";
import StatusIndicator from "~/components/StatusIndicator";
import TextOverflow from "~/components/TextOverflow";
import EnvironmentActions from "rwb/components/EnvironmentActions";
import { ProjectsResponse } from "rwb/hooks/useProjects";

interface EnvironmentsStatusProps {
  projectId: number;
  environments: ComputeEnvironment[] | null;
  mutateProjects: KeyedMutator<ProjectsResponse>;
}

const EnvironmentsTooltip = ({
  projectId,
  environments = [],
  mutateProjects,
  children,
}: EnvironmentsStatusProps & { children: ReactElement }) => {
  const [error, setError] = useState("");

  return (
    <Tooltip
      arrow
      variant="light"
      // Prevent click on tooltip from propagating to card and navigating user
      slotProps={{
        popper: {
          onClick: (e) => e.stopPropagation(),
          // Hide tooltip when overlaid stop env modal adds aria-hidden to popper
          sx: { '&[aria-hidden="true"]': { display: "none" } },
        },
      }}
      title={
        <Box p={1} width={250}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <T variant="subtitle2">Compute Environments</T>
            <IconButton size="small" component={Link} to={`/projects/${projectId}/environments`}>
              <SettingsToolIcon width={20} fill="#9e9e9e" color="#9e9e9e" />
            </IconButton>
          </Box>
          <Divider sx={{ my: 1 }} />
          {error && (
            <Alert severity="error" sx={{ my: 1 }}>
              {error}
            </Alert>
          )}
          <Stack gap={1} py={1}>
            {environments?.map((env) => (
              <Box key={env.id} display="flex" alignItems="center" gap={1}>
                <StatusIndicator active={env.status === EnvironmentStatus.RUNNING} />
                <TextOverflow variant="body2" flex={1}>
                  {env.name}
                </TextOverflow>
                <EnvironmentActions env={env} setError={setError} refreshEnvs={mutateProjects} />
              </Box>
            ))}
          </Stack>
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
};

const EnvironmentsStatus = ({
  projectId,
  environments = [],
  mutateProjects,
}: EnvironmentsStatusProps) => {
  const navigate = useNavigate();
  const environmentCount = environments?.length ?? 0;

  if (!environmentCount) {
    return (
      <Button
        sx={{ ml: -1 }}
        onClick={(e) => {
          e.preventDefault();
          navigate(`/projects/${projectId}/environments`, {
            state: { createNew: !environmentCount },
          });
        }}
      >
        <Box pr={1}>
          <PlusIcon width={10} />
        </Box>
        Add environment
      </Button>
    );
  }

  return (
    <EnvironmentsTooltip
      environments={environments}
      projectId={projectId}
      mutateProjects={mutateProjects}
    >
      <AvatarGroup max={5} sx={{ [`& .${avatarClasses.root}`]: { width: 30, height: 30 } }}>
        {environments?.map((env) => {
          const display = DISPLAY_COMPUTE_IMAGE[env.image_type];
          return (
            <Avatar
              key={env.id}
              sx={{
                bgcolor: "grey.200",
                filter: env.status === EnvironmentStatus.RUNNING ? "none" : "grayscale(100%)",
              }}
            >
              {display && <display.icon width={18} height={18} />}
            </Avatar>
          );
        })}
      </AvatarGroup>
    </EnvironmentsTooltip>
  );
};

export { EnvironmentsStatus };
