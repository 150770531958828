import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import queryString from "query-string";
import TextOverflow from "~/components/TextOverflow";
import Project from "rwb/types/interfaces/project";
import useProjects from "rwb/hooks/useProjects";

const Explanation = {
  default:
    "Are you sure you want to delete this project? Your compute environments will also be deleted. This action cannot be undone.",
  confirmation:
    "Your collaborators also have compute environments on this project. This action will delete their environments too. Are you sure?",
};

interface ModalProps {
  open: boolean;
  onClose: () => void;
  project: Project;
}

const ModalBody = ({
  project,
  onClose,
  isLoading,
  setIsLoading,
}: {
  project: Project;
  onClose: () => void;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const { data: projects, mutate: mutateProjects } = useProjects();
  const [error, setError] = useState("");
  const [needsConfirm, setNeedsConfirm] = useState(false);

  const { VITE_RWB_API } = import.meta.env;
  const projectsUrl = `${VITE_RWB_API}/projects`;
  const explanation = needsConfirm ? Explanation.confirmation : Explanation.default;

  const onDelete = async (withConfirm = false) => {
    setIsLoading(true);
    const qs = queryString.stringify({
      confirm: withConfirm,
    });

    const resp = await fetch(`${projectsUrl}/${project.id}?${qs}`, {
      method: "DELETE",
    });
    setIsLoading(false);
    return resp;
  };

  const handleDelete = async () => {
    setError("");
    const resp = await onDelete(needsConfirm);
    if (resp.ok) {
      const filteredProjectList = projects?.data?.filter((w) => w.id !== project.id) ?? [];
      // Optimistic update
      mutateProjects({
        data: filteredProjectList,
        total: (projects?.total ?? 1) - 1,
      });
      navigate("/projects", { replace: true });
    } else if (resp.status === 300) {
      setNeedsConfirm(true);
    } else {
      setError("Unable to delete project");
    }
  };

  return (
    <>
      <DialogTitle>
        <TextOverflow>Delete &quot;{project.title}&quot;</TextOverflow>
      </DialogTitle>
      <DialogContent sx={{ gap: 2, fontSize: 14 }}>
        {error && <Alert severity="error">{error}</Alert>}
        {explanation}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          onClick={handleDelete}
          sx={{
            backgroundColor: "attention.main",
            "&:hover": { backgroundColor: "attention.dark" },
          }}
        >
          {needsConfirm ? "Yes, Delete" : "Delete"}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

const DeleteProjectModal = (props: ModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = (event: object, reason: string) => {
    /**
     * Disable ability to escape modal while delete is processing
     */
    if (isLoading && reason === "backdropClick") {
      return;
    }
    props.onClose();
  };

  return (
    <Dialog fullWidth open={props.open} onClose={handleClose}>
      <ModalBody {...props} isLoading={isLoading} setIsLoading={setIsLoading} />
    </Dialog>
  );
};

export { DeleteProjectModal };
