import { useFetcher } from "~/hooks/useFetcher";
import { ConceptTreeResponse } from "catalog/types/interfaces/conceptResponse";
import { useSemanticCatalogEnabled } from "catalog/hooks/useCatalogEnabled";

const { VITE_CONCEPT_API } = import.meta.env;

const useConceptsTree = () => {
  const enabled = useSemanticCatalogEnabled();
  return useFetcher<ConceptTreeResponse>(enabled ? `${VITE_CONCEPT_API}/browse/tree` : null, {
    keepPreviousData: true,
  });
};

export default useConceptsTree;
