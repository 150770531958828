import { cloneElement, ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  OutlinedInput,
  Typography as T,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import useProjects from "rwb/hooks/useProjects";

type ProjectForm = { title: string; description: string };

const CreateForm = (props: { onClose: () => void }) => {
  const navigate = useNavigate();
  const { mutate } = useProjects();
  const [error, setError] = useState("");
  const form = useForm<ProjectForm>({
    defaultValues: { title: "", description: "" },
  });

  const onSubmit = async (data: ProjectForm) => {
    const { VITE_RWB_API } = import.meta.env;

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description ?? "");

    const resp = await fetch(`${VITE_RWB_API}/projects`, {
      method: "POST",
      body: formData,
    });

    if (resp.ok) {
      const id = await resp.json();
      await mutate();
      navigate(`/projects/${id}`);
    } else {
      setError("Unable to create project");
    }
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <DialogTitle>
        New Project
        <IconButton size="small" onClick={props.onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" onClose={() => setError("")} sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <FormControl>
          <FormLabel htmlFor="title">
            <T variant="label">Title *</T>
          </FormLabel>
          <Controller
            control={form.control}
            name="title"
            rules={{
              required: { value: true, message: "Required" },
              maxLength: { value: 255, message: "Title can't exceed 255 characters" },
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <OutlinedInput autoFocus id={field.name} size="small" error={!!error} {...field} />
                <FormHelperText error>{error?.message ?? " "}</FormHelperText>
              </>
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="description">
            <T variant="label">Description</T>
          </FormLabel>
          <Controller
            control={form.control}
            name="description"
            rules={{
              maxLength: { value: 2000, message: "Description can't exceed 2000 characters" },
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <OutlinedInput
                  id={field.name}
                  multiline
                  size="small"
                  rows={5}
                  error={!!error}
                  {...field}
                />
                <FormHelperText error>{error?.message ?? " "}</FormHelperText>
              </>
            )}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} size="medium" color="inherit" variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          disabled={!form.formState.isValid}
          loading={form.formState.isSubmitting}
          variant="contained"
          size="medium"
        >
          Create
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

const CreateProject = (props: { callToAction: ReactElement }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const boundCTA = cloneElement(props.callToAction, {
    onClick: () => setOpen(true),
  });

  return (
    <>
      {boundCTA}
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        /**
         * The "title" input should be auto-focused when this
         * dialog opens, but there's an issue that prevents that from
         * happening on dev builds: See this link
         * https://github.com/mui/material-ui/issues/33004
         *
         * Turning on "disableRestoreFocus" is a suitable workaround for
         * dev builds.
         */
        disableRestoreFocus={import.meta.env.DEV}
      >
        <CreateForm onClose={handleClose} />
      </Dialog>
    </>
  );
};

export default CreateProject;
