import React from "react";
import ReactDOM from "react-dom/client";
import yn from "yn";
import App from "~/App";
import "~/index.css";

if (yn(import.meta.env.VITE_MOCK_API_ENABLED)) {
  import("mocks/browser").then(async ({ worker }) => {
    await worker.start({
      onUnhandledRequest: "bypass",
    });
    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App />);
  });
} else {
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App />);
}
