import useUser from "~/contexts/user";
import yn from "yn";

const { VITE_CATALOG_ENABLED, VITE_SEMANTIC_CATALOG_ENABLED } = import.meta.env;

export const useSemanticCatalogEnabled = (): boolean => {
  const user = useUser();
  return (
    yn(VITE_SEMANTIC_CATALOG_ENABLED, { default: false }) &&
    !!user?.permissions?.includes("view:semantic-catalog-app")
  );
};

const useCatalogEnabled = (): boolean => {
  const user = useUser();
  return (
    yn(VITE_CATALOG_ENABLED, { default: false }) &&
    !!user?.permissions?.includes("view:catalog-app")
  );
};

export default useCatalogEnabled;
