import { FunctionComponent, PropsWithChildren, ReactElement } from "react";
import { Link as MuiLink, LinkProps, Box } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon/SvgIcon";
import {
  CustomImageIcon,
  JupyterLabsIcon,
  RStudioIcon,
  SASIcon,
  NextflowIcon,
  StreamlitIcon,
} from "~/assets/icons";

const Code = (props: PropsWithChildren) => (
  <Box
    className="code"
    sx={{
      display: "inline-block",
      fontFamily: "monospace",
      px: 0.5,
      my: 0.25,
      color: "code.main",
      backgroundColor: "grey.100",
    }}
    {...props}
  />
);

const Caption = (props: PropsWithChildren) => (
  <Box sx={{ fontSize: "0.875rem", ml: 0.5, mt: 1 }} {...props} />
);

const Link = (props: LinkProps) => (
  <MuiLink target="_blank" rel="noreferrer" underline="hover" {...props} />
);

enum ImageType {
  CUSTOM = "custom",
  JUPYTER = "jupyter",
  JUPYTER_DL = "jupyter_dl",
  HOVERNET = "hovernet",
  MANIFOLDOMICS = "manifoldomics",
  R_STUDIO = "rstudio",
  POSIT_WORKBENCH = "posit_workbench",
  SAS = "sas",
  NEXTFLOW = "nextflow",
  STREAMLIT_DEMO = "streamlit_demo",
  STREAMLIT_MGB = "streamlit_mgb",
}

export const DISPLAY_COMPUTE_IMAGE: Record<
  ImageType,
  {
    label: string;
    icon: typeof SvgIcon | FunctionComponent;
    description: string | ReactElement;
    caption?: string | ReactElement | undefined;
  }
> = {
  [ImageType.CUSTOM]: {
    label: "Custom Image",
    icon: CustomImageIcon,
    description: "Add your own custom image",
  },
  [ImageType.JUPYTER]: {
    label: "Jupyter - Scipy Notebook",
    icon: JupyterLabsIcon,
    description: (
      <Box>
        Jupyter notebook that includes popular packages from the scientific Python ecosystem and
        common utilities like <Code>curl</Code>, <Code>git</Code>, <Code>vim</Code>, etc. This is
        the <Code>jupyter/scipy-notebook</Code> image maintained by the Jupyter project team.
      </Box>
    ),
    caption: (
      <Caption>
        See this{" "}
        <Link href="https://jupyter-docker-stacks.readthedocs.io/en/latest/using/selecting.html#jupyter-scipy-notebook">
          link
        </Link>{" "}
        for more information.{" "}
      </Caption>
    ),
  },
  [ImageType.JUPYTER_DL]: {
    label: "Jupyter - Deep Learning",
    icon: JupyterLabsIcon,
    description: (
      <Box>
        Jupyter notebook that includes deep learning libraries like <Code>cuda</Code>,{" "}
        <Code>torch</Code>, and <Code>tensorflow</Code> for training neural networks. This is the
        <Code>orbyter-dl-dev</Code> image maintained by Manifold.
      </Box>
    ),
    caption: (
      <Caption>
        See this <Link href="https://hub.docker.com/r/manifoldai/orbyter-dl-dev">link</Link> for
        more information.{" "}
      </Caption>
    ),
  },
  [ImageType.HOVERNET]: {
    label: "Jupyter - Hovernet",
    icon: JupyterLabsIcon,
    description: (
      <Box>
        Jupyter notebook that includes deep learning libraries and the Hovernet image segmentation
        model for histology. This is the <Code>orbyter-dl-dev</Code> image maintained by Manifold
        with the Hovernet pre-trained model installed.
      </Box>
    ),
    caption: (
      <Caption>
        See <Link href="https://hub.docker.com/r/manifoldai/orbyter-dl-dev"> orbyter-dl-link </Link>{" "}
        and{" "}
        <Link href="https://github.com/vqdang/hover_net" target="_blank" rel="noreferrer">
          hovernet-repo
        </Link>{" "}
        for more information.
      </Caption>
    ),
  },
  [ImageType.MANIFOLDOMICS]: {
    label: "Jupyter - Manifoldomics",
    icon: JupyterLabsIcon,
    description: (
      <Box>
        Jupyter notebook that includes popular packages for data scientists and a private package
        for specialized bioinformatics analyses. This is the<Code>orbyter-ml-dev</Code> image
        maintained by Manifold with the private <Code>manifoldomics</Code> library installed.
      </Box>
    ),
    caption: (
      <Caption>
        See <Link href="https://hub.docker.com/r/manifoldai/orbyter-ml-dev">orbyter-link</Link> and{" "}
        <Link href="https://hub.docker.com/repository/docker/manifoldai/manifoldomics/general">
          manifoldomics-link
        </Link>{" "}
        for more information.
      </Caption>
    ),
  },
  [ImageType.R_STUDIO]: {
    label: "RStudio - Rocker",
    icon: RStudioIcon,
    description: (
      <Box>
        RStudio including the popular <Code>tidyverse</Code> package set for data science. This is
        the rocker/tidyverse image maintained by the Rocker project team.
      </Box>
    ),
    caption: (
      <Caption>
        See <Link href="https://rocker-project.org/images/versioned/rstudio.html">link</Link> for
        more information.
      </Caption>
    ),
  },
  [ImageType.POSIT_WORKBENCH]: {
    label: "RStudio Pro",
    icon: RStudioIcon,
    description: (
      <Box>
        RStudio Pro including a pre-installed standard epidemiology and bioinformatics toolset. This
        is the rstudio/rstudio-workbench image maintained by the Posit Workbench team.
      </Box>
    ),
    caption: (
      <Caption>
        See <Link href="https://posit.co/products/enterprise/workbench/">Posit Workbench</Link> for
        more information.
      </Caption>
    ),
  },
  [ImageType.SAS]: {
    label: "SAS Studio [Testing Purposes Only]",
    icon: SASIcon,
    description: (
      <Box>
        A comprehensive suite of SAS software components for statistical analysis, predictive
        modeling, and data visualization. Maintained by the SAS Analytics team.
      </Box>
    ),
    caption: (
      <Caption>
        See <Link href="https://www.sas.com/en_us/home.html">SAS</Link> for more information.
      </Caption>
    ),
  },
  [ImageType.NEXTFLOW]: {
    label: "Nextflow",
    icon: NextflowIcon,
    description: (
      <Box>
        Nextflow image for running bioinformatics pipelines. Nextflow pipelines are automatically
        integrated with AWS Batch Job Queue. Includes Jupyter notebook and terminal interfaces. The
        Jupyter notebook is based on the <Code>jupyter/scipy-notebook</Code>
        image maintained by the Jupyter project team.
      </Box>
    ),
    caption: (
      <Caption>
        See <Link href="https://www.nextflow.io/">nextflow</Link> for more information.
      </Caption>
    ),
  },
  [ImageType.STREAMLIT_DEMO]: {
    label: "Streamlit - Manifold Demo",
    icon: StreamlitIcon,
    description: (
      <Box>
        Multi-page Streamlit application that is running the various demos for the Science Cloud.
      </Box>
    ),
    caption: (
      <Caption>
        See the repo at <Link href="https://github.com/manifoldai/sc-demo">sc-demo</Link> for more
        information.
      </Caption>
    ),
  },
  [ImageType.STREAMLIT_MGB]: {
    label: "Streamlit - MGB",
    icon: StreamlitIcon,
    description: (
      <Box>Streamlit application that is running the various demos for the MGB Science Cloud.</Box>
    ),
    caption: (
      <Caption>
        Learn more about streamlit at the{" "}
        <Link href="https://streamlit.io/">streamlit homepage</Link>.
      </Caption>
    ),
  },
};

export default ImageType;
