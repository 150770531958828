import { useEffect, useState } from "react";

export const useDebounce = <T>(value: T, delay = 350): T => {
  // Initialize with input, return once with initial value then again after timeout updates state
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Update local state after configured delay on value parameter change
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel previous timeout if value parameter changes or component is unmounted
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
