import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ManifoldColorIcon, LlamaIcon } from "~/assets/icons";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";

const NotFound = () => {
  useDocumentTitle("Not Found");

  return (
    <Box>
      <Container>
        <Stack
          display="flex"
          height="100vh"
          alignItems="center"
          color="primary.dark"
          position="relative"
        >
          <Box
            component={Link}
            to="/"
            replace
            sx={{ color: "primary.dark", position: "absolute", top: 15 }}
          >
            <ManifoldColorIcon width={150} />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="250px"
            mb={2}
            sx={{ opacity: 0.32 }}
          >
            <LlamaIcon width={150} color="inherit" />
            <Typography variant="h1" color="inherit" fontWeight="medium" pt={0.5}>
              404
            </Typography>
          </Box>
          <Typography color="inherit" variant="subtitle2">
            The page you’re looking for is out to the zoo.
          </Typography>
          <Button variant="contained" component={Link} to="/" replace sx={{ marginTop: 4 }}>
            Take Me Home
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};

export default NotFound;
