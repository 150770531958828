import * as React from "react";
const SvgLogoManifoldBadgeColor = (props) => /* @__PURE__ */ React.createElement("svg", { width: 30, height: 30, viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_1423_159)" }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.48674 12.4775L-0.00125122 19.1594L5.11075 21.9548L7.48674 12.4775Z", fill: "#685BC7", style: {
  fill: "#685BC7",
  fill: "color(display-p3 0.4078 0.3569 0.7804)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.4952 5.54545L9.83916 3L0.911163 17.6818L7.70316 11.6136L14.4952 5.54545Z", fill: "#201547", style: {
  fill: "#201547",
  fill: "color(display-p3 0.1255 0.0824 0.2784)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.9758 24.7496L24.8158 27.545V21.9541L14.9758 24.7496Z", fill: "#685BC7", style: {
  fill: "#685BC7",
  fill: "color(display-p3 0.4078 0.3569 0.7804)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.10913 22.4541V27.545H23.0131L14.0611 24.9996L5.10913 22.4541Z", fill: "#201547", style: {
  fill: "#201547",
  fill: "color(display-p3 0.1255 0.0824 0.2784)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.0878 3L14.9758 5.79545L22.4398 12.4773L20.0878 3Z", fill: "#685BC7", style: {
  fill: "#685BC7",
  fill: "color(display-p3 0.4078 0.3569 0.7804)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.9753 4.47754L23.1353 13.0912L25.2713 21.6821L29.9273 19.1594L20.9753 4.47754Z", fill: "#201547", style: {
  fill: "#201547",
  fill: "color(display-p3 0.1255 0.0824 0.2784)",
  fillOpacity: 1
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_1423_159" }, /* @__PURE__ */ React.createElement("rect", { width: 30, height: 30, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }))));
export default SvgLogoManifoldBadgeColor;
