import { ComponentProps, FunctionComponent, useMemo } from "react";
import useConceptsTree from "catalog/hooks/useConceptsTree";
import {
  ConceptLookupResponse,
  EntityGroupNode,
  EntityInfo,
} from "catalog/types/interfaces/conceptResponse";
import ConceptCategory, { DISPLAY_CONCEPT_CATEGORY } from "catalog/types/enums/conceptCategory";
import ConceptEntity, { DISPLAY_CONCEPT_ENTITY } from "catalog/types/enums/conceptEntity";
import useConceptsLookup from "catalog/hooks/useConceptsLookup";
import { getTreeNodeById } from "catalog/utils/concept";

export interface ConceptGroup {
  name: string;
  id: string;
  counts: {
    type: ConceptEntity;
    count: number;
  }[];
  score: number;
  isLeafNode?: boolean;
  icon?: FunctionComponent<ComponentProps<"svg">>;
  color?: string;
  badge?: {
    label: string;
    icon: FunctionComponent<ComponentProps<"svg">>;
    color: string;
  };
  description?: string;
  tooltip?: string;
  type?: ConceptEntity;
}

const aggregateChildren = (
  children: Record<string, EntityGroupNode | null> = {},
  groupLookup: Record<string, EntityInfo> = {}
) => {
  const groupedChildCounts = Object.keys(children).reduce<
    Partial<{ [key in ConceptEntity]: number }>
  >((acc, id) => {
    const childInfo = groupLookup?.[id];
    if (childInfo) {
      const currentCount = acc[childInfo.type] ?? 0;
      return {
        ...acc,
        [childInfo.type]: currentCount + 1,
      };
    }
    return acc;
  }, {});

  return Object.entries(groupedChildCounts).map(([key, value]) => ({
    type: key as ConceptEntity,
    count: value,
  }));
};

const transformNodeToConceptGroup = (
  id: string,
  val: EntityGroupNode,
  parentIds: string[],
  groupId?: string,
  groupLookup?: ConceptLookupResponse["data"]
): ConceptGroup | null => {
  const isCategory = Object.values(ConceptCategory).includes(id as ConceptCategory);
  const childCounts = aggregateChildren(val?.children, groupLookup);
  const score = val?.score ?? 0;

  if (isCategory && childCounts.length) {
    const categoryInfo = DISPLAY_CONCEPT_CATEGORY[id as ConceptCategory];
    return {
      id,
      score: 0, // Reordered as per category logic
      icon: categoryInfo.icon,
      color: categoryInfo.color,
      name: categoryInfo.title,
      tooltip: categoryInfo.tooltip,
      counts: childCounts,
    };
  }

  const groupInfo = groupLookup?.[id];

  if (groupInfo) {
    const category = (parentIds[0] ?? groupId) as ConceptCategory;
    const categoryConfig = DISPLAY_CONCEPT_CATEGORY[category];
    return {
      id,
      score,
      name: groupInfo.name,
      type: groupInfo.type,
      badge: categoryConfig && {
        icon: categoryConfig.icon,
        color: categoryConfig.color,
        label: DISPLAY_CONCEPT_ENTITY[groupInfo.type],
      },
      description: groupInfo.description,
      counts: groupInfo.counts ?? childCounts,
      isLeafNode: !val?.children,
    };
  }
  return null;
};

export interface TypeCategorizedConceptGroup {
  type?: ConceptEntity;
  conceptGroups?: ConceptGroup[];
}

const categorizeConceptGroups = (
  conceptGroups: ConceptGroup[],
  currentGroupType?: ConceptEntity
): TypeCategorizedConceptGroup[] => {
  // Only subtopic has Derived and Question Group. Otherwise, we just return
  // a subgroup of 1. Since the Subgroup button will be hidden, type and
  // label won't be used.
  if (currentGroupType !== ConceptEntity.SUBTOPIC) {
    return [
      {
        type: currentGroupType,
        conceptGroups: conceptGroups,
      },
    ];
  }

  // If we are in subtopic, then break down the results to derived and question
  // group. Note that `filter` preserves the original sort order so that the
  // higher score is always before the lower scores.
  const questionGroup =
    conceptGroups.filter((item) => item.type === ConceptEntity.QUESTION_GROUP) || [];
  const derivedGroup =
    conceptGroups.filter((item) => item.type === ConceptEntity.DERIVED_GROUP) || [];

  // Order matters here since the Subgroup buttons will be rendered based on
  // the order of this array. We want the Derived Button to show up first.
  const result: TypeCategorizedConceptGroup[] = [];

  if (derivedGroup.length > 0) {
    result.push({
      type: ConceptEntity.DERIVED_GROUP,
      conceptGroups: derivedGroup,
    });
  }

  if (questionGroup.length > 0) {
    result.push({
      type: ConceptEntity.QUESTION_GROUP,
      conceptGroups: questionGroup,
    });
  }

  return result;
};

const filterValidNodes = (
  nodes: Record<string, EntityGroupNode | null>
): [string, EntityGroupNode][] =>
  Object.entries(nodes).filter(([, val]) => !!val) as [string, EntityGroupNode][];

/**
 * Given the current Concept Group ID, this hook will do the following:
 * 1. Gather all the details about the current Concept
 * 2. Gather all the details about its child Concepts
 * 3. Enrich the child Concept with display metadata that is ready for rendering
 * 4. Further breakdown the child Concepts to “types” if needed so that
 * users can select different groups (e.g. Derived Variables)
 */
export const useConceptGroupsList = (groupId: string = "") => {
  const { data: browseTree, isLoading: treeLoading, error: treeError } = useConceptsTree();
  const { data: groupLookup, isLoading: lookupLoading, error: lookupError } = useConceptsLookup();

  const typeCategorizedGroups = useMemo(() => {
    if (!browseTree?.data || !groupLookup?.data) return [];

    const { node, parentIds } = getTreeNodeById(groupId, browseTree.data);
    const nodeChildren = groupId ? node?.children : browseTree.data;

    if (!nodeChildren) return [];

    const validNodes = filterValidNodes(nodeChildren);

    // Convert the browse tree node into ConceptGroup objects that are enriched
    // with display metadata (e.g. icons and such).
    const conceptGroups = validNodes
      .map(([id, val]) =>
        transformNodeToConceptGroup(id, val, parentIds, groupId, groupLookup.data)
      )
      .filter(Boolean) as ConceptGroup[];

    // Sort the group naturally by score. In the backend, the score is implicitly
    // sorted by participant response count (more responses == higher score).
    conceptGroups.sort((a, b) => b.score - a.score);
    const currentGroupType = groupLookup.data[groupId]?.type;

    // Further break up the ConceptGroup objects by "type"
    // (e.g. Derived vs. Question).
    return categorizeConceptGroups(conceptGroups, currentGroupType);
  }, [groupId, browseTree, groupLookup]);

  return {
    loading: treeLoading || lookupLoading,
    error: treeError || lookupError,
    typeCategorizedGroups,
  };
};

export default useConceptGroupsList;
