import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import useUser from "~/contexts/user";
import Loading from "~/components/Loading";

export const ProtectedAppRoute = ({
  permission,
  children,
}: {
  permission: string;
  children: ReactNode;
}) => {
  const user = useUser();

  if (!user) {
    // Still loading user details from /me
    // TODO: Improve error handling/messaging if /me ever goes down
    return <Loading />;
  }

  // Route is either disabled via feature flag or user does not have matching permission
  if (!user.permissions?.includes(permission)) {
    return <Navigate to="/404" replace />;
  }

  return <>{children}</>;
};
