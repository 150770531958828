import useSWR, { Key, SWRConfiguration, SWRResponse } from "swr";
import { useRef } from "react";
import { safelyCamelize } from "~/utils/object";
import useSWRMutation, { SWRMutationConfiguration, SWRMutationResponse } from "swr/mutation";

const fetcher = async (url: string, options?: RequestInit) => {
  const res = await fetch(url, options);

  if (!res.ok) {
    const error: any = new Error();
    const resp = await res.json();
    error.message = JSON.stringify(resp);
    error.status = res.status;
    throw error;
  }

  return res.json();
};

const noopTransform = (data: any) => data;
interface ExtraFetcherOptions<ResponseType = any> {
  // Specify "data" to only return data prop from response, or true for the whole response
  shouldCamelize?: true | "data";
  skipCamelizeKeys?: string[];
  transform?: (data: ResponseType) => ResponseType;
}
const camelizingFetcher =
  <ResponseType>(
    {
      getNestedDataOnly = false,
      skipCamelizeKeys,
      transform = noopTransform,
    }: {
      getNestedDataOnly?: boolean;
    } & Pick<ExtraFetcherOptions<ResponseType>, "skipCamelizeKeys" | "transform">,
    options?: RequestInit
  ) =>
  async (url: string) => {
    const json = await fetcher(url, options);

    let key;
    try {
      const fullURL = new URL(url);
      key = fullURL.pathname;
    } catch {
      key = url;
    }

    // Always fall back to full response if json.data is empty
    const data = (getNestedDataOnly && json?.data) || json;
    // Note: skipCamelizeKeys is still useful here since it allows skipping *nested* objects
    return transform(safelyCamelize(data, false, skipCamelizeKeys));
  };

export const useFetcher = <ResponseType>(
  key: Key,
  options: SWRConfiguration & ExtraFetcherOptions = {}
): SWRResponse<ResponseType> => {
  const { shouldCamelize, skipCamelizeKeys, transform = noopTransform, ...swrOptions } = options;
  return useSWR(
    key,
    shouldCamelize
      ? camelizingFetcher<ResponseType>({
          getNestedDataOnly: shouldCamelize === "data",
          skipCamelizeKeys,
          transform,
        })
      : fetcher,
    swrOptions
  );
};

export const useStickyFetcher = <ResponseType>(
  key: Key,
  options: SWRConfiguration = {}
): SWRResponse<ResponseType> => {
  const val = useRef<ResponseType | undefined>();
  const { data, ...rest } = useFetcher<ResponseType>(key, options);
  if (data !== undefined) {
    val.current = data;
  }
  return {
    ...rest,
    data: val.current,
  };
};

interface ExtraMutatorProps extends ExtraFetcherOptions {
  requestInit?: RequestInit;
}
const getMutator =
  <MutatorArg = unknown>(fetcherProps: ExtraMutatorProps) =>
  async (key: Key, { arg }: { arg: MutatorArg }) => {
    const [path] = Array.isArray(key) ? key : [key];
    const {
      requestInit = {},
      shouldCamelize,
      skipCamelizeKeys,
      transform = noopTransform,
    } = fetcherProps;
    requestInit.headers = {
      "Content-Type": "application/json",
      ...(requestInit.headers || {}),
    };
    if (!requestInit.method) {
      requestInit.method = "PATCH";
    }
    if (typeof arg === "object") {
      requestInit.body = JSON.stringify(arg);
    }
    return shouldCamelize
      ? camelizingFetcher(
          {
            getNestedDataOnly: shouldCamelize === "data",
            skipCamelizeKeys,
            transform,
          },
          requestInit
        )(path)
      : fetcher(path, requestInit);
  };

export const useMutator = <Data = never, ExtraArg = never, Error = unknown>(
  key: Key,
  options: SWRMutationConfiguration<Data, Error> & ExtraMutatorProps = {}
): SWRMutationResponse<Data, Error, ExtraArg> => {
  const { requestInit, shouldCamelize, ...swrOptions } = options;
  return useSWRMutation<Data, Error, Key, ExtraArg>(
    key,
    getMutator<ExtraArg>({
      requestInit,
      shouldCamelize,
    }),
    swrOptions
  );
};
