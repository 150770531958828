enum ConceptEntity {
  CONCEPT = "concept",
  TOPIC = "topic",
  SUBTOPIC = "subtopic",
  CONDITION = "condition",
  SITE = "site",
  HISTOLOGY = "histology",
  QUESTION = "question",
  QUESTION_GROUP = "question_group",
  DERIVED_VAR = "derived_variable",
  DERIVED_GROUP = "derived_group",
  PARTICIPANT = "participant",
}

export const DISPLAY_CONCEPT_ENTITY: Record<ConceptEntity, string> = {
  [ConceptEntity.CONCEPT]: "Concept",
  [ConceptEntity.TOPIC]: "Topic",
  [ConceptEntity.SUBTOPIC]: "Subtopic",
  [ConceptEntity.CONDITION]: "Condition",
  [ConceptEntity.SITE]: "Cancer Site",
  [ConceptEntity.HISTOLOGY]: "Histology",
  [ConceptEntity.QUESTION]: "Question",
  [ConceptEntity.QUESTION_GROUP]: "Question Group",
  [ConceptEntity.DERIVED_VAR]: "Derived Variable",
  [ConceptEntity.DERIVED_GROUP]: "Derived Variable Group",
  [ConceptEntity.PARTICIPANT]: "Participant",
};

export default ConceptEntity;
