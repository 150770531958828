import { useState } from "react";
import { Divider, IconButton, Link, Menu, MenuItem, styled } from "@mui/material";
import UserAvatar from "~/components/UserAvatar";

const StyledMenuItem = styled(MenuItem)({
  padding: 0,
  a: {
    width: "100%",
    padding: "8px 16px",
  },
});

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        id="account-button"
        aria-controls={anchorEl ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? "true" : undefined}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          p: 0.5,
          "&[aria-expanded='true'], :hover": { bgcolor: "rgba(255, 255, 255, 0.16)" },
        }}
      >
        <UserAvatar size={28} />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "account-button",
        }}
        PaperProps={{ sx: { borderRadius: 2 }, elevation: 2 }}
        sx={{ marginTop: 0.5, marginLeft: 1 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <StyledMenuItem>
          <Link
            underline="none"
            onClick={handleClose}
            href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}
            color="inherit"
          >
            Contact Support
          </Link>
        </StyledMenuItem>
        <StyledMenuItem>
          <Link
            underline="none"
            onClick={handleClose}
            href="/privacy"
            color="inherit"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </StyledMenuItem>
        <Divider sx={{ mx: 2 }} />
        <StyledMenuItem>
          <Link underline="none" onClick={handleClose} href="/logout" color="inherit">
            Sign Out
          </Link>
        </StyledMenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
