import { Box, IconButton, MenuItem, Select, styled } from "@mui/material";
import { FirstPage, NavigateNext, NavigateBefore, LastPage } from "@mui/icons-material";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";
import pluralize from "pluralize";
import { trackEvent } from "~/utils/monitoring";

const buildPaginationString = (pageNum: number, pageSize: number, total: number): string => {
  const start = (pageNum - 1) * pageSize + 1;
  const end = pageNum * pageSize < total ? pageNum * pageSize : total;

  if (total > 0 && total <= pageSize) {
    return `${total.toLocaleString()} ${pluralize("result", total)}`;
  }

  return `${start} - ${end} of ${total.toLocaleString()} ${pluralize("result", total)}`;
};

type LimitedPaginationProps = {
  total: number;
  defaultPageSize: number;
  pageKey?: string;
  pageSizeKey?: string;
  pageSizeOptions?: number[];
  analyticsPrefix?: string;
};

const StyledIconButton = styled(IconButton)({
  padding: 2,
  "&:disabled": {
    opacity: 0.3,
  },
});

const LimitedPagination = ({
  total,
  defaultPageSize,
  pageKey = "page",
  pageSizeKey = "perPage",
  pageSizeOptions,
  analyticsPrefix,
}: LimitedPaginationProps) => {
  const [pageNum, setPageNum] = useQueryParam(pageKey, withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam(
    pageSizeKey,
    withDefault(NumberParam, defaultPageSize)
  );
  const pageCount = Math.ceil(total / pageSize) || 0;
  const prevDisabled = pageNum === 1;
  const nextDisabled = pageNum === pageCount;

  const handlePaginationChange = (newPage: number) => {
    const scrollWrapper = document.querySelector("main");
    setPageNum(newPage === 1 ? undefined : newPage, "replaceIn");
    // 0ms timeout after page num update to ensure smooth scroll transition isn't interrupted by render
    setTimeout(() => scrollWrapper?.scrollTo({ top: 0, behavior: "smooth" }), 0);
  };

  const trackNavEvent = (pageAction: string, newPage: number) => {
    if (analyticsPrefix) {
      trackEvent(`${analyticsPrefix}_pagination_navigated`, {
        page: pageNum,
        new_page: newPage,
        page_count: pageCount,
        action: pageAction,
        // TODO entity_type and query for catalog
      });
    }
  };

  const handlePageFirst = () => {
    const newPage = 1;
    handlePaginationChange(newPage);
    trackNavEvent("first", newPage); // TODO add query to all of these
  };

  const handlePageForward = () => {
    if (pageNum < pageCount) {
      const newPage = pageNum + 1;
      handlePaginationChange(newPage);
      trackNavEvent("forward", newPage);
    }
  };

  const handlePageBack = () => {
    if (pageNum > 1) {
      const newPage = pageNum - 1;
      handlePaginationChange(newPage);
      trackNavEvent("back", newPage);
    }
  };

  const handlePageLast = () => {
    const newPage = pageCount;
    handlePaginationChange(newPage);
    trackNavEvent("last", newPage);
  };

  // Hide pagination if not enough data to ever need it
  if (total <= Math.min(pageSize, ...(pageSizeOptions || []))) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" height={43}>
      <Box
        flex="1"
        display="flex"
        alignItems="center"
        sx={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {total > 0 && <Box fontSize={13}>{buildPaginationString(pageNum, pageSize, total)}</Box>}
      </Box>
      <Box display="flex" alignItems="center" gap={0.25}>
        {pageSizeOptions && (
          <Select
            aria-label="Select page size"
            size="small"
            value={pageSize}
            onChange={(event) => {
              // Go to first page when changing size
              setPageNum(1);
              setPageSize(Number(event.target.value) || defaultPageSize);
            }}
          >
            {pageSizeOptions.map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        )}
        <StyledIconButton onClick={handlePageFirst} disabled={prevDisabled}>
          <FirstPage />
        </StyledIconButton>
        <StyledIconButton onClick={handlePageBack} disabled={prevDisabled}>
          <NavigateBefore />
        </StyledIconButton>
        <StyledIconButton onClick={handlePageForward} disabled={nextDisabled}>
          <NavigateNext />
        </StyledIconButton>
        <StyledIconButton onClick={handlePageLast} disabled={nextDisabled}>
          <LastPage />
        </StyledIconButton>
      </Box>
    </Box>
  );
};

export default LimitedPagination;
