import yn from "yn";
import { AwsRum } from "aws-rum-web";
import mixpanel from "mixpanel-browser";
import User from "~/types/interfaces/user";

const {
  VITE_AWS_REGION,
  VITE_AWS_RUM_APP_ID,
  VITE_AWS_GUEST_ROLE_ARN,
  VITE_AWS_IDENTITY_POOL_ID,
  VITE_REACT_APP_VERSION,
  VITE_MIXPANEL_TOKEN,
  VITE_MIXPANEL_DEBUG,
  VITE_CUSTOMER,
} = import.meta.env;

const mixPanelEnabled = VITE_MIXPANEL_TOKEN && VITE_CUSTOMER;

if (mixPanelEnabled) {
  // Near entry of your product, init Mixpanel
  mixpanel.init(VITE_MIXPANEL_TOKEN, {
    debug: yn(VITE_MIXPANEL_DEBUG),
    track_pageview: false, // TODO page tracking
    persistence: "localStorage",
  });

  // "customer" should be set on all mixpanel events
  mixpanel.register({
    customer: VITE_CUSTOMER,
  });
}

const rumClient = (() => {
  try {
    return new AwsRum(VITE_AWS_RUM_APP_ID, VITE_REACT_APP_VERSION, VITE_AWS_REGION, {
      enableRumClient: !!VITE_AWS_RUM_APP_ID,
      sessionSampleRate: 1,
      guestRoleArn: VITE_AWS_GUEST_ROLE_ARN,
      identityPoolId: VITE_AWS_IDENTITY_POOL_ID,
      disableAutoPageView: true,
      telemetries: [
        "performance",
        "errors",
        [
          "http",
          {
            addXRayTraceIdHeader: true,
            urlsToExclude: [/\.api\.smarty\.com\//, /cdc\.gov/, /mixpanel\.com/],
          },
        ],
      ],
      allowCookies: true,
      enableXRay: true,
    });
  } catch (e) {
    console.error(e);
  }
})();

export const trackEvent = (eventName: string, properties: Record<string, any> = {}) => {
  if (mixPanelEnabled) {
    mixpanel.track(eventName, properties);
  }
  rumClient?.recordEvent(eventName, properties);
};

export const identify = (user: User) => {
  // ignore integration test users
  const email = user.email;

  if (mixPanelEnabled) {
    if (email.endsWith("@sc.manifold.ai") || email.startsWith("integration_test")) {
      mixpanel.register({
        $ignore: true, // tell mixpanel to ignore further events https://docs.mixpanel.com/docs/tracking-best-practices/bot-traffic https://github.com/mixpanel/mixpanel-iphone/issues/277
      });
    }

    mixpanel.identify(email);
    mixpanel.register({
      user_id: email, // mixpanel already sends a `User ID` property automatically, but on the backend we send that as `user_id`
    });

    // set up user profile
    mixpanel.people.set({
      $email: email,
      $name: user.name,
      $first_name: user.given_name,
      $last_name: user.family_name,
      customer: email.endsWith("@manifold.ai") ? "manifold" : VITE_CUSTOMER, // most users are from a single customer, but put manifolders under manifold
    });
  }

  rumClient?.addSessionAttributes({ oidcUserId: email });
};

export default rumClient;
