import { ForwardedRef, forwardRef, useRef, useEffect, useState } from "react";
import { styled, Tooltip, Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography/Typography";

const StyledTypography = styled(Typography)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

const TextWrapper = forwardRef((props: TypographyProps, ref: ForwardedRef<HTMLSpanElement>) => (
  <StyledTypography ref={ref} {...props} />
));

TextWrapper.displayName = "TextWrapper";

const TextOverflow = ({
  autoTooltip,
  children,
  ...typographyProps
}: TypographyProps & { autoTooltip?: boolean }) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [overflowing, setOverflowing] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const isOverflowing = ref.current.scrollWidth > ref.current.clientWidth;
      setOverflowing(isOverflowing);
    }
  }, [children, typographyProps]);

  const tooltipEnabled = autoTooltip && overflowing;

  return (
    <Tooltip
      arrow
      title={tooltipEnabled ? children : null}
      enterDelay={750}
      enterNextDelay={750}
      componentsProps={{ tooltip: { sx: { maxWidth: 750 } } }}
    >
      <TextWrapper ref={ref} {...typographyProps}>
        {children}
      </TextWrapper>
    </Tooltip>
  );
};

export default TextOverflow;
