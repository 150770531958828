import { SWRResponse } from "swr";
import { useMatch } from "react-router-dom";
import { Box, Skeleton, Alert, Button, Stack, Typography as T } from "@mui/material";
import LimitedPagination from "~/components/LimitedPagination";
import { PAGE_SIZE, ProjectsResponse } from "rwb/hooks/useProjects";
import CreateProject from "rwb/pages/projects/components/CreateProject";
import { PlusIcon, ProjectsIcon } from "~/assets/icons";
import ProjectCard from "rwb/pages/projects/components/ProjectCard";

const EmptyPlaceholder = () => {
  const showWelcome = !!useMatch("/projects");
  return (
    <Stack gap={3} alignItems="center">
      <ProjectsIcon />
      <Stack gap={1} alignItems="center">
        {showWelcome && <T variant="h6">Welcome to Workbench</T>}
        <T variant={showWelcome ? "body2" : "body1"}>To get started, create your first project.</T>
      </Stack>
      <CreateProject
        callToAction={
          <Button variant="contained" startIcon={<PlusIcon />}>
            New Project
          </Button>
        }
      />
    </Stack>
  );
};

interface ProjectListProps {
  data: SWRResponse<ProjectsResponse>;
  pageSize?: number;
  showPagination?: boolean;
}

const ProjectList = ({ data, pageSize = PAGE_SIZE, showPagination = true }: ProjectListProps) => {
  const { data: projects, isLoading, error, mutate } = data;
  const isEmpty = !isLoading && !projects?.total;
  const showEmpty = !error && isEmpty;

  if (error) {
    return (
      <Alert severity="error" variant="outlined" sx={{ my: 2 }}>
        Unable to load projects
      </Alert>
    );
  }

  if (showEmpty) {
    return <EmptyPlaceholder />;
  }

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px,1fr))",
          gridGap: ({ spacing }) => spacing(2),
          py: 1.5,
        }}
      >
        {isLoading &&
          Array.from({ length: Math.min(pageSize, 8) }).map((_, i) => (
            <Skeleton key={i} variant="rounded" height={210} />
          ))}
        {projects?.data.map((project) => (
          <ProjectCard key={project.id} data={project} mutateProjects={mutate} />
        ))}
      </Box>
      {showPagination && (
        <LimitedPagination total={projects?.total ?? 0} defaultPageSize={pageSize} />
      )}
    </>
  );
};

export default ProjectList;
