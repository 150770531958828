// This is an example of how to embed a Sigma workbook.
// This page is not intended to be used on its own.
import AppContainer from "~/components/AppContainer";
import useAppHeader from "~/contexts/header";
import { useFetcher } from "~/hooks/useFetcher";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";

const { VITE_ACCESS_CONTROLLER_API, VITE_SIGMA_EMBED_WORKBOOK } = import.meta.env;

const title = "Dashboards V2";

const SigmaEmbed = () => {
  useAppHeader({ title });
  useDocumentTitle(title);
  const refreshInterval = 24 * 60 * 60 * 1000;
  const leeway = 5 * 1000;
  const url = `${VITE_ACCESS_CONTROLLER_API}/users/sigma/url/${VITE_SIGMA_EMBED_WORKBOOK}`;
  const { data: sigmaEmbedUrl, isLoading } = useFetcher<{ url: string }>(url, {
    // Refresh every 24 hours (with 5 seconds of leeway)
    refreshInterval: refreshInterval - leeway,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    keepPreviousData: true,
  });

  return <AppContainer loading={isLoading} iframeSrc={sigmaEmbedUrl?.url} />;
};

export default SigmaEmbed;
