import { useEffect } from "react";
import { useLocation } from "react-use";
import rumClient from "~/utils/monitoring";

const useRecordPageView = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== undefined) {
      rumClient?.recordPageView({ pageId: location.pathname ?? "" });
    }
  }, [location.pathname]);
};

export default useRecordPageView;
