import { Button, ButtonProps } from "@mui/material";
import { OverridableComponent } from "@mui/types";
import { ButtonTypeMap } from "@mui/material/Button/Button";

interface BasicButtonProps extends ButtonProps {
  endArrow?: boolean;
}

const BasicButton = ({ sx = {}, disabled, endArrow, ...props }: BasicButtonProps) => (
  <Button
    sx={{
      backgroundColor: "#fff",
      color: "primary.dark",
      fontSize: 13,
      py: 0.75,
      px: 1.75,
      ...sx,
    }}
    variant="contained"
    color="secondary"
    disabled={disabled}
    endIcon={
      endArrow ? (
        <svg width="8" height="5" viewBox="0 0 8 5" fill="none">
          <path
            d="M2.90573 4.49995L0.305725 1.89995C-0.0109418 1.58328 -0.0816084 1.22095 0.093725 0.812951C0.269058 0.404951 0.581392 0.200618 1.03073 0.199951H6.18072C6.63072 0.199951 6.94339 0.404284 7.11872 0.812951C7.29406 1.22162 7.22306 1.58395 6.90573 1.89995L4.30572 4.49995C4.20572 4.59995 4.09739 4.67495 3.98073 4.72495C3.86406 4.77495 3.73906 4.79995 3.60573 4.79995C3.47239 4.79995 3.34739 4.77495 3.23073 4.72495C3.11406 4.67495 3.00573 4.59995 2.90573 4.49995Z"
            fill="black"
          />
        </svg>
      ) : null
    }
    {...props}
  />
);

export default BasicButton as OverridableComponent<ButtonTypeMap<BasicButtonProps>>;
