import { ArrayParam, NumberParam, StringParam, useQueryParams } from "use-query-params";

const useFilterParams = () =>
  useQueryParams({
    q: StringParam,
    page: NumberParam,
    tags: ArrayParam,
  });

export default useFilterParams;
