import { ElementType } from "react";
import { Typography, styled } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography/Typography";

const StyledTypography = styled(Typography)({
  fontWeight: 600,
  overflowWrap: "break-word",
  lineHeight: 1.2,
  fontSize: [20, 22],
});

const PageHeader = (props: { component?: ElementType } & TypographyProps) => (
  <StyledTypography component="h1" variant="h6" {...props} />
);

export default PageHeader;
