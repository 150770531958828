import { useLocation } from "react-router-dom";
import AppContainer from "~/components/AppContainer";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";
import useAppHeader from "~/contexts/header";
import { TitleBreadcrumbs } from "~/components/AppHeader";

const { VITE_DATA_DASHBOARD_URL } = import.meta.env;

const DataOverview = () => {
  const location = useLocation();
  useDocumentTitle("Data Overview");
  useAppHeader({
    title: (
      <TitleBreadcrumbs
        crumbs={[
          { title: "Data Browser", href: "/data-browser" },
          { title: "Overview", href: location.pathname },
        ]}
      />
    ),
  });
  return <AppContainer iframeSrc={VITE_DATA_DASHBOARD_URL} />;
};

export default DataOverview;
