import { Box } from "@mui/material";
import { ManifoldMonoBadgeIcon } from "~/assets/icons";

const HeaderLogo = () => {
  return (
    <Box position="absolute" top={15}>
      <ManifoldMonoBadgeIcon />
    </Box>
  );
};

export default HeaderLogo;
