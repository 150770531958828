import rumClient from "~/utils/monitoring";

const SEARCHABLE_SCHEMAS = ["RAW", "ENRICHED", "STANDARDIZED", "RESEARCH"]; // case-sensitive
const HIDDEN_ELEMENTS_TAG = "ux.hidden"; // case-sensitive

/**
 * Groups tags in the same classification as an "OR" filter and separates
 * tags from different classifications as an "AND" filter clause
 * @param tags - List of fully qualified tag names from filter form input
 */
const mapTagClassificationGroups = (
  tags: string[] = []
): { keys: string[]; values: string[] }[] => {
  const groupedTags = tags.reduce<Record<string, string[]>>((acc, tag = "") => {
    const [classification, tagName] = tag.split(".");
    const classificationTags = acc[classification] ?? [];
    return { ...acc, [classification]: [...classificationTags, tagName] };
  }, {});

  return Object.entries(groupedTags).map(([classification, tags]) => ({
    keys: ["tags.tagFQN.keyword", "combinedTags.tagFQN"],
    // Map tags back to their fully qualified name
    values: tags.map((tag) => `${classification}.${tag}`),
  }));
};

/**
 * Helper function to handle building filter matrix across multiple filter categories/selections.
 * Filters within the same category are OR, filters across type have an AND relationship
 * @param tags - Contains elasticsearch term keys and values from query params
 */
export const buildFilterMatrix = (tags: string[] = []) => {
  const filterGroups = [
    ...mapTagClassificationGroups(tags),
    // Add future filter group key/value pairs here
  ];

  const filtersWithSearchableSchemas = filterGroups.concat([
    {
      keys: ["databaseSchema.name", "table.databaseSchema.name"],
      values: SEARCHABLE_SCHEMAS,
    },
  ]);

  const filters = { query: { bool: {} } };
  try {
    filters.query.bool = {
      must_not: {
        bool: {
          should: [
            { term: { "tags.tagFQN.keyword": HIDDEN_ELEMENTS_TAG } },
            { term: { "combinedTags.tagFQN": HIDDEN_ELEMENTS_TAG } },
          ],
        },
      },
      must: filtersWithSearchableSchemas
        .filter((group) => group.values?.length)
        .map((group) => ({
          // across filter groups use AND
          bool: {
            // within each filter group, OR all keys & values
            should: group.keys.flatMap((key) =>
              group.values.map((value) => ({
                term: { [key]: value },
              }))
            ),
          },
        })),
    };
  } catch (e) {
    console.error(e);
    rumClient?.recordError(e);
  }
  return filters;
};
