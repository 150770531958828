import {
  createContext,
  Dispatch,
  isValidElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { equals } from "ramda";

interface IAppHeaderContext {
  title: ReactNode;
  centerContent: ReactNode;
  setTitle: Dispatch<SetStateAction<ReactNode>>;
  setCenterContent: Dispatch<SetStateAction<ReactNode>>;
}

/**
 * Helper function to check whether app bar content should be updated to
 * avoid max call stack exceeded when `useAppHeader` is called with a ReactElement passed in
 */
const compareReactNodes = (node1: ReactNode, node2: ReactNode): boolean => {
  if (isValidElement(node1) && isValidElement(node2)) {
    // Both are React elements, compare their type and props
    return node1.type === node2.type && equals(node1.props, node2.props);
  }

  // For non-element nodes, do a simple equality check
  return equals(node1, node2);
};

const AppHeaderContext = createContext<IAppHeaderContext>({
  title: [],
  centerContent: null,
  setTitle: () => {},
  setCenterContent: () => {},
});

export const AppHeaderProvider = ({ children }: { children: ReactNode }) => {
  const [title, setTitle] = useState<ReactNode>([]);
  const [centerContent, setCenterContent] = useState<ReactNode>(null);

  return (
    <AppHeaderContext.Provider
      value={{
        title: title,
        setTitle: setTitle,
        centerContent,
        setCenterContent,
      }}
    >
      {children}
    </AppHeaderContext.Provider>
  );
};

const useAppHeader = ({
  title: initialTitle,
  centerContent: initialCenterContent,
}: Partial<Pick<IAppHeaderContext, "title" | "centerContent">> = {}) => {
  const context = useContext<IAppHeaderContext>(AppHeaderContext);

  if (!context) {
    throw new Error("useAppHeader must be used within an AppHeaderProvider");
  }

  // Update the context value if an initial value is passed to hook
  useEffect(() => {
    if (initialTitle !== undefined && !compareReactNodes(initialTitle, context.title)) {
      context.setTitle(initialTitle);
    }
  }, [initialTitle, context]);

  useEffect(() => {
    if (
      initialCenterContent !== undefined &&
      !compareReactNodes(initialCenterContent, context.centerContent)
    ) {
      context.setCenterContent(initialCenterContent);
    }
  }, [initialCenterContent, context]);

  return context;
};

export default useAppHeader;
