import { Avatar } from "@mui/material";
import useUser from "~/contexts/user";

interface UserAvatarProps {
  size?: number;
}

const UserAvatar = ({ size }: UserAvatarProps) => {
  const user = useUser();

  if (!user) {
    return null;
  }

  return (
    <Avatar
      src={user.picture}
      sx={{
        width: size,
        height: size,
        fontSize: 10,
      }}
    >
      {/* Fallback to showing initials if no profile picture */}
      {user.given_name?.[0]}
      {user.family_name?.[0]}
    </Avatar>
  );
};

export default UserAvatar;
